import "core-js"
import React from "react"
import ReactDOM from "react-dom"
import { HashRouter } from "react-router-dom"
import "../../i18nextInit"
import "../../assets/css/hypdex.css"
import "../../assets/css/hypdex-responsive.css"
import loader from "../../assets/images/loader.png"

import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

const loading = (
  <div className="preloader">
    <img className="preloader__image" width="60" src={loader} alt="" />
  </div>
)

const Layout = React.lazy(() => import("../../components/application/layout"))

const Index = () => {
  
  return (
    <>
      <React.Suspense fallback={loading}>
        <ToastContainer
          className="toast-hyperdex"
          position="top-center"
          autoClose={5000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <Layout />
      </React.Suspense>
    </>
  )
}

export default Index
